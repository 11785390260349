export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563",
};

export const adminRoot = "/app";
export const buyUrl = "https://1.envato.market/k4z0";
export const searchPath = `${adminRoot}/#`;
export const servicePath = "https://api.coloredstrategies.com";

export const currentUser = {
  id: 1,
  title: "Sarah Kortney",
  img: "/assets/img/profiles/l-1.jpg",
  date: "Last seen today 15:24",
  role: UserRole.Admin,
};

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = false;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = false;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];

//shoppers
export const GET_SHOPPERS = "/shoppers";
export const IMPORT_SHOPPERS_CSV = "/upload_shopper_csv";
export const UPDATE_SHOPPER = "/shoppers/";
export const ACTIVATE_SHOPPER = "/shoppers/activate/";
export const DEACTIVATE_SHOPPER = "/shoppers/deactivate/";
export const RESET_PASSWORD = "/shoppers/resetpassword/";
export const CREATE_ALL_MISSING_SIP_EXTENSIONS =
  "/shoppers/create_all_mising_sip_extensions";

//shops
export const GET_SHOPS = "/shops";
export const MANUAL_SHOP_IMPORT = "/shops/import_shops";
export const UPDATE_CLIENT_PHONE = "/shops/";
export const PAUSE_UNPAUSE = "/shops//pause_unpause/";
export const MANUALLY_CLOSE_SHOP = "/shops/manually_close/";
export const MANUALLY_IMPORT_SHOPS = "/shops/import_shops";
export const MANUALLY_SYNC_COMPLETED_JOBS = "/shops/manual_sync_completd_shops";
export const GET_SHOP_SYNC_STATUS = "/shops/sync_completd_shops_status";
export const GET_SHOP_IMPORT_STATUS = "/shops/new_shop_import_completd_status";

//recordings
export const GET_RECORDINGS = "/recordings/";

//dids
export const GET_DIDS = "/dids";

//voicemail recordings
export const GET_VOICEMAIL_RECORDINGS = "/voicemal_recordings";
export const UPDATE_VOICEMAIL_RECORDINGS = "/voicemal_recordings/";
export const UPLOAD_VOICEMAIL_RECORDING = "/upload_voicemail_recording";

//sms
export const GET_SMS = "/sms/";

//call reports
export const GET_CALL_REPORT = "/call_report";
export const GET_CLIENTS = "/sassie_get_clients";
export const GET_SURVEY = "/sassie_get_surveys/";

//dashboard
export const GET_MAIN_DASHBOARD = "/admin_main_dashboard";
export const NEW_CUSTOMER_GRAPH = "/admin_main_dashboard/new_customer_graph";
export const NEW_SALES_GRAPH = "/admin_main_dashboard/new_sales_graph";

//carousel
export const GET_LIVE_AFFIRMATIONS = "/admin_carousels/get_live_affirmations";
export const GET_CAROUSEL_AFFIRMATIONS = "/admin_carousels/affirmation";
export const UPDATE_CAROUSEL_AFFIRMATIONS = "/admin_carousels/affirmation";

export const GET_LIVE_MEDITATIONS = "/admin_carousels/get_live_meditations";
export const GET_CAROUSEL_MEDITATIONS = "/admin_carousels/meditation";
export const UPDATE_CAROUSEL_MEDITATIONS = "/admin_carousels/meditation";

export const GET_LIVE_VLOGS = "/admin_carousels/get_live_vlogs";
export const GET_CAROUSEL_VLOGS = "/admin_carousels/vlog";
export const UPDATE_CAROUSEL_VLOGS = "/admin_carousels/vlog";

export const GET_LIVE_LIVE_MEETINGS = "/admin_carousels/get_live_meetings";
export const GET_CAROUSEL_LIVE_MEETINGS = "/admin_carousels/meeting";
export const UPDATE_CAROUSEL_LIVE_MEETINGS = "/admin_carousels/meeting";

export const GET_LIVE_PODCASTS = "/admin_carousels/get_live_podcasts";
export const GET_CAROUSEL_PODCASTS = "/admin_carousels/podcast";
export const UPDATE_CAROUSEL_PODCASTS = "/admin_carousels/podcast";

//content categories
export const GET_CATEGORY_DISTRIBUTION =
  "/admin_content_categories/distributionReport";

//notifications
export const GET_PUSH_NOTIFICATIONS = "/admin_push_notification/all";
export const NEW_PUSH_NOTIFICATION = "/admin_push_notification/all";

// splash screen affirmations
export const GET_SPLASH_SCREEN = "/admin_splash_screen";
export const TEST_AFFIRMATION = "/admin_splash_screen/test_affirmation";
export const UPDATE_SPLASH_SCREEN = "/admin_splash_screen";

// default covers
export const GET_DEFAULT_COVERS = "/admin_default_covers";
export const UPDATE_DEFAULT_COVERS = "/upload_default_covers";

//content email
export const SEND_CONTENT_EMAIL = "/send_new_content_email";
export const SEND_MARKETING_EMAIL = "/send_new_content_email/meeting_scheduled";

//affirmations
export const GET_AFFIRMATIONS = "/admin_affirmation";
export const ADD_AFFIRMATION_STEP_1 = "/admin_affirmation/step_1";
export const UPDATE_AFFIRMATION = "/admin_affirmation/";
export const DELETE_AFFIRMATION = "/admin_affirmation/";

//meditations
export const GET_MEDITATIONS = "/admin_meditation";
export const ADD_MEDITATION_STEP_1 = "/admin_meditation/step_1";
export const UPDATE_MEDITATION = "/admin_meditation/";
export const DELETE_MEDITATION = "/admin_meditation/";

//vlogs
export const GET_VLOGS = "/admin_vlog";
export const ADD_VLOG_STEP_1 = "/admin_vlog/step_1";
export const UPDATE_VLOG = "/admin_vlog/";
export const DELETE_VLOG = "/admin_vlog/";

//vlogs
export const GET_PODCASTS = "/admin_podcast";
export const ADD_PODCAST_STEP_1 = "/admin_podcast/step_1";
export const UPDATE_PODCAST = "/admin_podcast/";
export const DELETE_PODCAST = "/admin_podcast/";

//gifters
export const GET_GIFTERS = "/admin_gifters/";

//customers
export const GET_CUSTOMERS = "/admin_clients";
export const GET_CUSTOMER = "/admin_clients/";
export const UPDATE_CUSTOMER = "/admin_clients/";
export const ADD_CLIENT_MANUALLY = "/admin_clients_add_manually";

export const CANCEL_SUBSCRIPTION = "/admin_clients/cancel_subscription";

export const GET_CANCELED_SUBSCRIPTIONS =
  "/admin_subscriptions/canceled_subscription";
export const GET_CANCELED_SUB_SEARCH =
  "/admin_subscriptions/canceled_subscription_search";

//subscriptions
export const GET_SUBSCRIPTION = "/admin_subscriptions/";

//system performance
export const GET_CPU = "/admin_system_performance/cpu";
export const GET_MEMORY = "/admin_system_performance/memory";
export const GET_DISK = "/admin_system_performance/disk";
export const GET_NETWORK = "/admin_system_performance/network";
export const GET_DISK_IO = "/admin_system_performance/disk_io";

//media play counts
export const GET_TOP_AFFIRMATIONS =
  "/admin_report_media_count/top_affirmations";
export const GET_AFFIRMATION_COUNT =
  "/admin_report_media_count/all_affirmations";
export const GET_TOP_PODCASTS = "/admin_report_media_count/top_podcasts";
export const GET_PODCAST_COUNT = "/admin_report_media_count/all_podcasts";
export const GET_TOP_VLOGS = "/admin_report_media_count/top_vlogs";
export const GET_VLOG_COUNT = "/admin_report_media_count/all_vlogs";
export const GET_TOP_MEETINGS = "/admin_report_media_count/top_meetings";
export const GET_MEETING_COUNT = "/admin_report_media_count/all_meetings";
export const GET_TOP_MEDITATIONS = "/admin_report_media_count/top_meditations";
export const GET_MEDITATION_COUNT = "/admin_report_media_count/all_meditations";
export const GET_ALL_SUMMARY_COUNT =
  "/admin_report_media_count/all_summary_count";

//reports
export const GET_SUBSCRIPTION_REPORT = "/admin_subscriptionPageReport";

export const GET_PARTIAL_SUBSCRIPTIONS =
  "/admin_clients/not_completed_sign_ups";
export const SEND_SIGNUP_REMINDER =
  "/admin_clients/not_completed_sign_ups_send_reminder";

export const GET_FAILED_PAYMENTS = "/admin_failed_payments";
export const SEND_PAYMENT_REMINDER = "/admin_failed_payments/send_reminder";

// Manual payments
export const GET_MANUAL_PAYMENTS = "/admin_manual_payments/";

//invoices
export const GET_INVOICES = "/admin_invoices/";

//refund
export const ADD_REFUND = "/admin_refund";

//reset password
export const PASSWORD_RESET = "/admin_clients/reset_password/";

//user
export const GET_USER = "/admin_users/";
export const UPDATE_USER = "/admin_users/";
export const UPDATE_PASSWORD = "/admin_users/update_password/";
export const GET_USERS = "/admin_users/";
export const DELETE_USER = "/admin_users/";
export const ADD_USER = "/admin_users";

//meetings
export const GET_ALL_MEETINGS = "/admin_meetings";
export const ADD_MEETING = "/admin_meetings";
export const GET_ZOOM_USERS = "/admin_meetings/get_zoom_users";
export const GET_MEETING = "/admin_meetings/";
export const UPDATE_MEETING = "/admin_meetings/";
export const DELETE_MEETING = "/admin_meetings/";

//templated affirmations
export const TEMPLATED_AFFIRMATIONS = "/admin_templated_affirmations/";
export const GET_TEMPLATED_AFFIRMATION_CATEGORIES =
  "/admin_templated_affirmations/get_categories";
export const UPLOAD_AFFIRMATION_CSV =
  "/admin_templated_affirmations/upload_csv";

//advertising
export const GET_ALL_ADS = "/admin_advertising/";
export const ADD_ADVERT = "/add_advertising";
export const UPDATE_ADVERT = "/update_advertising";
export const DELETE_ADVERT = "/admin_advertising/";

//advertising positions
export const ADVERTISING_TOP_VIDEO_MAIN = "/admin_advertising_top_video_main";

//featured content positions
export const GET_TOP_MAIN_PAGE =
  "/admin_featured_content/admin_featured_content_top_main/top";
export const GET_FEATURED_CONTENT = "/admin_featured_content/";

//content categories
export const CONTENT_CATEGORIES = "/admin_content_categories";

//courses
export const GET_ALL_COURSES = "/admin_courses/";

//course modules
export const GET_COURSE_MODULES = "/admin_course_modules/all_modules/";
export const GET_MODULE = "/admin_course_modules/";
export const GET_MODULE_MEDIA = "/admin_course_module_medias/all_medias/";
export const GET_MODULE_MEDIA_BY_ID = "/admin_course_module_medias/";

//module meetings
export const GET_MODULE_MEETINGS =
  "/admin_course_module_meetings/all_meetings/";
export const GET_MODULE_MEETING_BY_ID = "/admin_course_module_meetings/";

// update course media
export const UPDATE_VIDEO = "/admin_courses/update_video/";
export const UPDATE_IMAGE = "/admin_courses/update_image/";

//sms reports
export const GET_SMS_REPORT = "/sms_report";

//admin login
export const LOGIN = "/admin_login/send_login_code";
export const VERIFY_LOGIN_CODE = "/admin_login/verify_login_code";

//upload file
export const UPLOAD_FILE = "/upload_files";
export const UPDATE_FILES = "/update_files";
